/**
 * Polymer requirements for cross-browser compat
 */
import '@babel/polyfill';
import '@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js';
import '@webcomponents/webcomponentsjs/webcomponents-bundle.js';
/**
 * Polyfill ResizeObserver for the ai-resize-pubsub
 */
import ResizeObserver from 'resize-observer-polyfill';
if(! ('ResizeObserver' in window)){
    window.ResizeObserver = ResizeObserver;
}
/**
 * import the custom js file for this route
 */
import './../scripts/app.custom.js';
/**
 * Site wide resources/components
 */
import './ai-components/ai-resize-pubsub.js';//this will pretty much always be needed


import './ai-components/ai-header.js';
import './ai-components/ai-menu.js';
import './ai-components/ai-menu-item.js';
import './ai-components/ai-menu-link.js';
import './ai-components/ai-submenu.js';
import './ai-components/ai-trigger-nav-icon.js';
import './ai-components/ai-menu-drawer.js';